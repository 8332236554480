"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import numeral from "numeral";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

// components
import TermsDialog from "@/sections/refund/TermsDialog";
import SnackbarWithIcon from "@/components/snack-bar/SnackbarWithIcon";

// fetch data
import fetcher from "@/libs/fetcher";

// types
import {
  CashbackData,
  ApiResponseCashback,
  ApiResponseClaimCashbackData,
} from "@/@types/refound";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import useMe from "@/hooks/fetchers/useMe";

interface State extends SnackbarOrigin {}

const RefundPage = () => {
  const { refresh } = useMe();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cashback, setCashback] = useState<number>(0);
  const [cashbackProfit, setcashBackProfit] = useState<string>("0");
  const [cashbackDayofweek, setCashbackDayofweek] = useState<number>(0);
  const [dayOfWeek, setDayOfWeek] = useState<number>(0);
  const [dayText, setDayText] = useState<string>("");
  const [cashbackData, setCashbackData] = useState<CashbackData | null>(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("An error occurred!");
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const fetchCashback = async (): Promise<void> => {
    setLoading(true);
    const response: ApiResponseCashback = await fetcher(
      "/api/v1/users/cashback"
    );
    const result = response.data;
    if (result) {
      setCashback(result?.cashback);
      setcashBackProfit(result?.cashback_profit);
      setCashbackDayofweek(result?.cashback_dayofweek);
      setDayOfWeek(result?.day_of_week);
      setDayText(result?.day_text);
    }
    setCashbackData(result);
    setLoading(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const claimCashback = async () => {
    try {
      const response: ApiResponseClaimCashbackData = await fetcher(
        `/api/v1/users/claim-cashback`
      );
      const status = response?.status === true ? "success" : "warning";
      setSeverity(`${status}`);
      setMessage(`${response?.msg}`);
      refresh();
      setOpen(true);
      setDialogOpen(false);
    } catch (error) {
      setSeverity("error");
      setMessage(`เกิดข้อผิดพลาด`);
      setOpen(true);
    }
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    fetchCashback();
  }, []);
  if (loading) {
    return (
      <>
        <Grid sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
      <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardActionArea onClick={handleOpenDialog}>
              <CardContent component="div">
                <Typography sx={{ color: (theme) => theme.gradient[900] }}>ຈຳນວນເງິນຄືນ</Typography>
                <SavingsOutlinedIcon
                  sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900]}}
                ></SavingsOutlinedIcon>
                <Typography variant="h4" sx={{ textAlign: "center",color: (theme) => theme.gradient[900] }}>
                  {numeral(cashback).format("0,0.00")}
                </Typography>
                <Grid component="div">
                  <Chip sx={{ color: (theme) => theme.gradient[900] || "primary" }} label="ກວດເບິ່ງແລະໄດ້ຮັບຈໍານວນທີ່ສູນເສຍ"></Chip>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center",color: (theme) => theme.gradient[900] }}>
          <Typography>❗️ ຈຳນວນເງິນຄືນທີ່ສະສົມຈະໄດ້ຮັບທຸກໆຄັ້ງ{dayText} ❗️</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardContent>
              <CurrencyExchangeOutlinedIcon
                sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900] }}
              ></CurrencyExchangeOutlinedIcon>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>ການສູນເສຍ</Typography>
              <Divider></Divider>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>{cashbackProfit} %</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SnackbarWithIcon 
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
        vertical={vertical}
        horizontal={horizontal}
      />
      <TermsDialog
        cashbackData={cashbackData}
        open={dialogOpen}
        closeDialog={handleCloseDialog}
        getClaimCashback={claimCashback}
      />
    </Grid>
  );
};

export default RefundPage;
