import React, { Fragment } from "react";
import numeral from "numeral";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";

// mui icon
import CloseIcon from "@mui/icons-material/Close";

interface ResponsiveDialogProps {
  cashbackData: CashbackData | null;
  open: boolean;
  closeDialog: () => void;
  getClaimCashback: () => void;
}

// type
import { CashbackData } from "@/@types/refound";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TermsDialog: React.FC<ResponsiveDialogProps> = ({
  cashbackData,
  open,
  closeDialog,
  getClaimCashback,
}) => {
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={closeDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            borderRadius: (theme) => theme.shape.borderRadius + "px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <Grid sx={{ mb: 1, mt: 5 }}>
                        <Button variant="contained" color='info' fullWidth> <Typography variant='h5' color='white'>ໄດ້ເງິນຄືນ</Typography></Button>
                    </Grid> */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{ color: (theme) => theme.gradient[900] }}>ຮັບຈໍານວນທີ່ສູນເສຍ</Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
              >
                <Grid xs={6} sm={6} md={6}>
                  <Typography fontSize="small" sx={{ color: (theme) => theme.gradient[900] }}>
                    ຈໍານວນການສູນເສຍສະສົມ{" "}
                    {numeral(cashbackData?.cashback).format("0,0.00")}
                  </Typography>
                </Grid>
                <Grid xs={6} sm={6} md={6}>
                  <Typography fontSize="small" sx={{ color: (theme) => theme.gradient[900] }}>
                    Cashback{" "}
                    {numeral(cashbackData?.cashback_profit).format("0,0.00")}%
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 3, mb: 3, textAlign: "center", color: (theme) => theme.gradient[900] }}>
                ຈໍານວນການສູນເສຍສະສົມ
              </Typography>
              <Typography variant="h3" sx={{ mb: 3, textAlign: "center", color: (theme) => theme.gradient[900] }}>
                {numeral(cashbackData?.cashback).format("0,0.00")}
              </Typography>
              {cashbackData?.cashback_dayofweek !==
                cashbackData?.day_of_week && (
                <Button
                  variant="contained"
                  // color="error"
                  fullWidth
                  onClick={getClaimCashback}
                >
                  {" "}
                  ยอดเสียของคุณสามารถรับได้ใน {cashbackData?.day_text}
                </Button>
              )}
              {cashbackData?.cashback_dayofweek ===
                cashbackData?.day_of_week && (
                <Button
                  onClick={getClaimCashback}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <Typography sx={{ color: (theme) => theme.gradient[900] }}>ໄດ້ເງິນຄືນ</Typography>{" "}
                </Button>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>ກົດລະບຽບການຮັບກິດຈະກຳຖອນ "ຈຳນວນທີ່ເສຍຄືນ"</Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                1. ກົດຮັບເງິນຄືນ "ຈຳນວນທີ່ເສຍ" ໄດ້ທຸກ{cashbackData?.day_text} ເວລາ 8:00 - 11:59 ໂມງ
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                2. ໃນກໍລະນີຖອນເງິນເຂົ້າບັນຊີ “ກະເປົ໋າເງິນ” ຕ້ອງເສຍເງິນຢ່າງໜ້ອຍ 1 ບາດ ແລະ 
                ຍອດເງິນໃນກະເປົາເງິນເຫຼືອໜ້ອຍກວ່າ 10 ບາດເພື່ອຖອນຄືນ
              </Typography>
              <Typography sx={{ mt: 5, mb: 5, color: (theme) => theme.gradient[900] }}>
                ກົດລະບຽບການ “ຮັບເງິນຄືນ” 
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                1 . ກ່ອນຈະກົດ “ຮັບ” “ເສຍເງິນຄືນ” ຄວນມີຍອດເຫຼືອໜ້ອຍກວ່າ 10 ບາດ ສະນັ້ນ, ເມື່ອຖອນເງິນແລ້ວ 
                ຈະມີເງື່ອນໄຂໃນການຖອນເງິນສູງສຸດ 10 ເທົ່າ 
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                2. “ເສຍຄືນ” ທີ່ໄດ້ຮັບຕ້ອງ ມີການຫັນເປັນ 2 ຄັ້ງເພື່ອສາມາດຖອນໄດ້ທັນທີເຖິງ 10 ເທົ່າຂອງການສູນເສຍທັງຫມົດ
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                3. "ການສູນເສຍຄືນ" ສາມາດຮັບໄດ້ເຖິງ 100,000 ບາດຕໍ່ຄັ້ງ 
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                4. ຖ້າການພະນັນຜິດປົກກະຕິ, ລະບົບຈະອັດຕະໂນມັດ ຫັກເຄຣດິດທັນທີ ແລະບໍ່ສາມາດຄືນເງິນໄດ້
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default TermsDialog;
